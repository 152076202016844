<template>
  <v-container fluid tag="section">
    <v-row align-content="center">
      <v-col
        cols="12" sm="7" md="7" lg="7"
         class="pb-1 d-flex align-center"
      >
        <span class="headline text-capitalize accent--text mt-3" v-text="$route.name"></span>
         <v-btn rounded small fab color="sidebar" depressed class="ml-4" to="/tc-admin/restaurante/registrar">
            <v-icon small>mdi-plus</v-icon>
          </v-btn>   
      </v-col>
      <v-col
        cols="12"
        class="pb-1"
        md="5" lg="5" sm="5"
      >
        <v-text-field
            class=""
            hide-details
            prepend-inner-icon="mdi-magnify"
            single-line
            outlined
            rounded
            dense
            color="primary"
            placeholder="Buscar restaurante"
            v-model="search"
          ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card flat class="mt-0">
          <v-card-title>
<!--             <v-row justify="end">
              <v-col col="6" class="d-flex justify-end pt-0">
                <v-btn rounded small color="sidebar" class="mr-2" @click="modal_user = true">
                  <v-icon small>mdi-plus</v-icon>
                  Nuevo
                </v-btn>                 
              </v-col>
            </v-row> -->
          </v-card-title>
          <v-sheet class="" v-if="load_user">
            <v-responsive max-width="900" class="mx-auto">
              <v-skeleton-loader
                ref="skeleton"
                type="table"
                class="mx-auto"
              ></v-skeleton-loader>
            </v-responsive>
          </v-sheet>      
            <v-data-table
            v-else
            :headers="headers"
            :items="restaurants"
            class="elevation-0 striped"
            :search="search"
            no-data-text="No se encontraron Usuarios"
            no-results-text="No se encontraron Coincidencias"
            single-select
            show-select
            fixed-header
            item-key="id"
            loading-text="Obteniendo Usuarios"
            v-model="selected_user"
          >
           <!--  <template v-slot:top="{ pagination, options, updateOptions }">
              <v-data-footer 
                :pagination="pagination" 
                :options="options"
                @update:options="updateOptions"
                items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
            </template> -->
            <template v-slot:item.logo="{item}">
                <v-avatar size="32">
                  <img :src="storage+item.logo" />
                </v-avatar>
              </template>
            <template v-slot:item.name="{ item }">
              <span class="font-weight-bold">{{ item.name }}</span>
            </template>
            <template v-slot:item.status="{ item }">
              <v-chip class="my-2" dark :color="item.status == 0 ? 'blue-grey' : 'success'" small v-text="item.status == 0 ? 'Inhabilidato' : 'Habilitado'"></v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="text-right">
                <v-btn icon color="primary lighten-1" @click="$router.push('/tc-admin/restaurante/'+item.id+'/administrar')">
                  <v-icon color="primary lighten-1">mdi-cog-transfer-outline</v-icon>
                </v-btn>
                <v-btn icon color="primary lighten-1" @click="$router.push('/tc-admin/restaurante/'+item.id)">
                  <v-icon color="primary lighten-1">mdi-pencil</v-icon>
                </v-btn>
                 <v-btn icon @click="changeStatus(item)">
                  <v-icon :color="item.status == 0 ? 'blue-grey' : 'cyan'" v-text="item.status == 0 ? 'mdi-toggle-switch-off' : 'mdi-toggle-switch'"></v-icon>
                </v-btn>                  
              </div>
            </template>
          </v-data-table>            
        </v-card>
      </v-col>
    </v-row>


    
    <base-material-snackbar
      v-model="snackbar.active"
      :type="snackbar.type"
      bottom
      right
    >
      {{ snackbar.text }}
    </base-material-snackbar>    
    <v-dialog v-model="load_delete" persistent width="300">
      <v-card color="info" dark>
        <v-card-text>
          <span class="white--text font-weight mb-2">
            <v-icon>mdi-trash-can-outline</v-icon>
            Cambiando Estatus
          </span>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-1"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

  export default {
    name: 'Users',
    data () {
      return {
        snackbar:{
          text:'',
          active: false,
          type:''
        },
        search:'',
        selected_user: [],
        load_user: false,
        saving_user: false,
        show: false,
        load_delete: false,
        users: [],
        roles:[],
        restaurants: [],
        headers: [
          {
            text: '',
            value: 'logo',
            sortable: false,
            width: '60',
            align: 'center',
          },
          { text: 'Restaurante', value: 'name' },
          { text: 'Correo', value: 'email' },
          { text: 'Estatus', value: 'status' },
          { text: '', value: 'actions' },
        ],
        formUser:{
          username: '',
          password: '',
          rol: '',
          restaurant_id: '',
        },
        id_user: '',
        action_user: 'Crear',
        modal_user: false,
        storage: '',
      }
    },
    created(){
      this.getRestaurants();
      this.storage = process.env.VUE_APP_IMG_URL_API + 'images/restaurants/';
    },
    methods: {
      async getRestaurants(){
        this.load_user= true;
        try{
          const response = await this.$api.get('api/restaurants');

          this.restaurants = response.data.data.restaurants;

          this.load_user= false;        
        } 
        catch(error){
          this.load_user= false;
          this.snackbar = {
            text: 'Lo sentimos, hubo un error al intentar obtener los Restaurante',
            type: 'error',
            active:true,
          };
          
        }
      },
      async changeStatus(item){
        const status = item.status == 1 ? 'Inhabilitado' : 'Habilitado';
        const msj = `Se cambiara el estatus del restaurante ${item.name} a ${status}`;

        const confirm = await this.$root.$confirm('¿Estas Seguro?',msj, { type: 'confirm', btnConfirmText: 'Si, Cambiar', btnCancelText: 'Descartar' });

        if(confirm){
          this.load_delete= true;
          const url = 'api/restaurants/status';
          try{
            const {data} = await this.$api.post(url, {restaurant_id: item.id, status: item.status == 1 ? 0 : 1});         
            this.load_delete= false;
            this.getRestaurants();
            this.search = '';
            this.snackbar = {
              text: data.message,
              type: 'success',
              active: true            
            }
          }
          catch(error){
            this.load_delete= false;
            if(error.response.status == "400"){
              this.snackbar = {
                text: error.response.data.message,
                type: 'error',
                active: true            
              }    
            }
            else{
              this.snackbar = {
                text: 'Lo sentimos, hubo un error al intentar cambiar el Estatus.',
                type: 'error',
                active: true            
              }              
            }
          }
        }
      },
    },
  }
</script>
